import React, { useState } from "react";

import { Action, Category } from "constants/events";
import { Dispensary } from "custom-types/Dispensary";
import { useEventTracker } from "hooks/useEventTracker";
import { useSelector } from "redux/hooks";
import { getCurrentDeliveryZone } from "redux/selectors/dispensary";

import RetailerDetailsModal from "components/botanic/RetailerDetailsModal";
import ScheduleStatus from "components/botanic/ScheduleStatus";

type Props = {
  className?: string;
  dispensary: Dispensary;
};

const BusinessHours: React.FC<Props> = ({ className, dispensary }) => {
  const deliveryZone = useSelector(getCurrentDeliveryZone);
  const [showRetailerDetails, setShowRetailerDetails] = useState(false);
  const { timeZone, id, currentStatuses, preorderConfigured } = dispensary;
  const { publishEvent } = useEventTracker();

  const handleRedesignToggle = (isOpen: boolean) => {
    setShowRetailerDetails(isOpen);
    publishEvent({
      action: Action.click,
      category: Category.dispensaryHours,
      dispensaryId: id,
      label: isOpen ? "open" : "closed",
    });
  };

  return (
    <div className={className}>
      <button
        onClick={() => {
          handleRedesignToggle(true);
        }}
        aria-label="view dispensary hours"
      >
        <ScheduleStatus
          className="flex-shrink-0 text-green underline"
          currentStatus={currentStatuses?.store}
          preorderConfigured={preorderConfigured}
          scheduleType="store"
          showArrow={true}
          timeZone={timeZone}
        />
      </button>
      {showRetailerDetails && (
        <RetailerDetailsModal
          dispensary={dispensary}
          deliveryZone={deliveryZone?.deliveryServiceArea}
          onDismissed={() => handleRedesignToggle(false)}
        />
      )}
    </div>
  );
};

export default BusinessHours;
