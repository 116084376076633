import { useEffect, useState } from "react";

import { Dispensary } from "custom-types/Dispensary";
import { FulfillmentPreference } from "custom-types/User";
import useUserFulfillmentPreference from "hooks/useUserFulfillmentPreference";
import { useSelector } from "redux/hooks";
import { getCurrentDeliveryZone } from "redux/selectors/dispensary";
import { getIsValidStreetAddress } from "redux/selectors/location";

import FulfillmentToggle from "components/botanic/FulfillmentToggle";
import FulfillmentTogglePlaceholder from "components/botanic/FulfillmentToggle/FulfillmentTogglePlaceholder";
import DeliveryValidation from "components/Dispensary/DeliveryValidation";
import PickupReadinessInfo from "components/Dispensary/PickupReadinessInfo/PickupReadinessInfo";

const FulfillmentToggleWrapper: React.FC<{
  dispensary: Dispensary;
}> = ({ dispensary }) => {
  const deliveryZone = useSelector(getCurrentDeliveryZone);
  const isValidAddress = useSelector(getIsValidStreetAddress);
  const [loading, setLoading] = useState(true);
  const [openValidation, setOpenValidation] = useState(false);
  const { userFulfillmentPreference, setUserFulfillmentPreference } =
    useUserFulfillmentPreference();

  const {
    currentStatuses,
    deliveryServiceAreaRanges,
    hasDeliveryEnabled,
    id: dispensaryId,
    pickupEnabled,
    preorderConfigured,
    scheduledDeliveryEnabled,
  } = dispensary;

  const { deliveryServiceArea } = deliveryZone || {};
  const deliveryOnly =
    !preorderConfigured && !pickupEnabled && hasDeliveryEnabled;

  useEffect(() => {
    if (userFulfillmentPreference !== undefined) {
      if (deliveryOnly) {
        setUserFulfillmentPreference(FulfillmentPreference.DELIVERY);
      }
      setLoading(false);
    }
  }, [userFulfillmentPreference]);

  const handleFulfillmentToggle = (isPickup: boolean) => {
    const newValue = isPickup ? `pickup` : `delivery`;

    setOpenValidation(!isPickup);
    setUserFulfillmentPreference(newValue);
  };

  return (
    <>
      {loading ? (
        <FulfillmentTogglePlaceholder className="h-[122px]" />
      ) : (
        <>
          <FulfillmentToggle
            alwaysAllowDelivery
            deliveryAvailable={
              currentStatuses?.delivery?.isOpen || scheduledDeliveryEnabled
            }
            deliveryFee={
              deliveryServiceArea?.fee || deliveryServiceAreaRanges?.minFee
            }
            deliveryMin={
              deliveryServiceArea?.orderMinimum ||
              deliveryServiceAreaRanges?.maxOrderMinimum
            }
            fulfillmentCookie={userFulfillmentPreference}
            onClick={handleFulfillmentToggle}
            pickupAvailable={currentStatuses?.pickup?.isOpen ?? false}
            preorderAvailable={preorderConfigured}
            alwaysAllowPickup={false}
            hasAddress={isValidAddress}
          />
          <div className="my-sm min-h-[50px]">
            {userFulfillmentPreference === "delivery" && (
              <DeliveryValidation
                dispensary={dispensary}
                openValidation={openValidation}
                setOrderPreference={setUserFulfillmentPreference}
              />
            )}
            {userFulfillmentPreference === "pickup" && (
              <div className="py-[7px]">
                <PickupReadinessInfo
                  dispensaryId={dispensaryId}
                  hideSkeleton={true}
                />
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default FulfillmentToggleWrapper;
