import React from "react";

import { Dispensary } from "custom-types/Dispensary";
import { useSelector } from "redux/hooks";
import { getIsOrderingAllowed } from "redux/selectors/complianceRules";

import PickupReadinessInfo from "../PickupReadinessInfo/PickupReadinessInfo";
import DefaultPickupFulfillmentTreatment from "./DefaultPickupFulfillmentTreatment";
import FulfillmentToggleWrapper from "./FulfillmentToggleWrapper";
import RequestOnlineOrderingTreatment from "./RequestOnlineOrderingTreatment";

const DispensaryHeaderFulfillment: React.FC<{
  dispensary: Dispensary;
}> = ({ dispensary }) => {
  const isLeaflyOrderingAllowed = useSelector(getIsOrderingAllowed);

  const {
    featureTier,
    hasDeliveryEnabled,
    id: dispensaryId,
    inStoreCartEnabled,
    pickupEnabled,
    retailType,
  } = dispensary;

  const hideRequestOnlineOrdering =
    featureTier === "info" || inStoreCartEnabled;

  if (retailType === "clinic" || !isLeaflyOrderingAllowed) {
    return null;
  }

  if (hasDeliveryEnabled) {
    return <FulfillmentToggleWrapper dispensary={dispensary} />;
  }

  return (
    <div>
      {pickupEnabled ? (
        <PickupReadinessInfo
          dispensaryId={dispensaryId}
          renderDefaultTreatment={() => <DefaultPickupFulfillmentTreatment />}
          renderExtraBadgeContent={() => (
            <div>
              &nbsp;
              <span className="leading-none after:content-['•']" />
              &nbsp;Free&nbsp;
              <span className="leading-none after:content-['•']" />
              &nbsp;No minimum
            </div>
          )}
        />
      ) : (
        !hideRequestOnlineOrdering && (
          <RequestOnlineOrderingTreatment dispensaryId={dispensaryId} />
        )
      )}
    </div>
  );
};

export default DispensaryHeaderFulfillment;
