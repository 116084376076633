import React, { useEffect, useRef, useState } from "react";
import { trackEvent } from "@leafly-com/web-utils";

import { DeliveryZoneDecisionResponse } from "custom-types/Compliance";
import { Dispensary } from "custom-types/Dispensary";
import { Location } from "custom-types/Location";
import { updateLocation } from "redux/action-creators/location";
import { useDispatch, useSelector } from "redux/hooks";
import {
  SET_DELIVERY_ZONE,
  SetDeliveryZoneAction,
} from "redux/reducers/dispensary";
import { getCurrentDeliveryZone } from "redux/selectors/dispensary";
import {
  getFormattedAddress,
  getIsValidStreetAddress,
  getLocationState,
} from "redux/selectors/location";
import { convertGooglePlaceIdToLocation } from "utils/convertGoogleGeocoderToLocation";

import DeliveryValidationModal from "components/botanic/DeliveryValidationModal";
import DeliveryValidationButton from "components/botanic/DeliveryValidationModal/DeliveryValidationButton";

type Props = {
  openValidation: boolean;
  setOrderPreference: (arg0: "delivery" | "pickup") => void;
  dispensary: Dispensary;
};

const DeliveryValidation: React.FC<Props> = ({
  openValidation,
  setOrderPreference,
  dispensary,
}) => {
  const dispatch = useDispatch();

  const deliveryZone = useSelector(getCurrentDeliveryZone);
  const userLocation = useSelector(getLocationState);
  const isValidAddress = useSelector(getIsValidStreetAddress);
  const formattedAddress = useSelector(getFormattedAddress) || "";

  // We need to have a formattedAddress set to the fetch/set the deliveryZone
  // on mount in DeliveryValidationModal
  useEffect(() => {
    if (isValidAddress && !userLocation.formattedAddress && formattedAddress) {
      updateLocation(dispatch, { ...userLocation, formattedAddress });
    }
  }, [userLocation, formattedAddress, isValidAddress]);

  const hasPickupEnabled = dispensary?.pickupEnabled;

  const pickupStatus = dispensary.currentStatuses?.pickup?.isOpen
    ? "open"
    : "closed";

  const isPickupOpenNow = pickupStatus === "open";

  const showPickupCTA = hasPickupEnabled && isPickupOpenNow;

  const [showDeliveryValidation, setShowDeliveryValidation] = useState(
    !isValidAddress && openValidation,
  );

  const buttonRef = useRef();

  // fills in extra address data from google that location picker cares about
  const getGeocoderLocation = async (newAddress: Location) => {
    const placeId = newAddress?.placeId || userLocation?.place_id;

    if (!placeId) {
      return;
    }

    const location = await convertGooglePlaceIdToLocation(placeId);

    const cookieLocation = { ...newAddress, ...location };

    updateLocation(dispatch, cookieLocation);
  };

  const openModal = () => {
    trackEvent(
      "Dispensary Menu - Delivery Address Validation Modal",
      "Click",
      "Open Delivery Address Validation Modal",
    );

    setShowDeliveryValidation(true);
  };

  const handleTryPickup = () => {
    trackEvent(
      "Dispensary Menu - Delivery Address Validation Modal",
      "Click",
      "Switched to Pickup",
    );

    setOrderPreference("pickup");
    setShowDeliveryValidation(false);
  };

  const validateDelivery = (address: Location) => {
    getGeocoderLocation(address);
    setShowDeliveryValidation(false);

    trackEvent(
      "Dispensary Menu - Delivery Address Validation Modal",
      "Click",
      "Confirmed Delivery Address",
    );
  };

  const updateDeliveryZone = (result: DeliveryZoneDecisionResponse) => {
    dispatch<SetDeliveryZoneAction>({
      deliveryZone: result,
      type: SET_DELIVERY_ZONE,
    });
  };

  return (
    <div className="mb-sm">
      <DeliveryValidationButton
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
        ref={buttonRef as any}
        handleClick={openModal}
        address={formattedAddress}
        inZone={deliveryZone?.result}
      />
      <DeliveryValidationModal
        complianceCallback={updateDeliveryZone}
        defaultAddress={userLocation}
        dispensary={dispensary}
        deliveryZone={deliveryZone?.deliveryServiceArea}
        handlePrimaryAction={validateDelivery}
        handleSecondaryAction={handleTryPickup}
        onDismissed={() => setShowDeliveryValidation(false)}
        primaryActionLabel="confirm address"
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: fix me please, do not replicate
        returnFocusRef={buttonRef as any}
        showModal={showDeliveryValidation}
        secondaryActionLabel={showPickupCTA ? "try pickup instead" : undefined}
        width={500}
      />
    </div>
  );
};

export default DeliveryValidation;
