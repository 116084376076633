import { useRouter } from "next/router";
import { stringify } from "qs";

import sanitizePage from "utils/sanitizePage";

import useDomain from "./useDomain";

export default (params?: string[], preserveParamOrder?: boolean): string => {
  const router = useRouter();
  const { query, asPath } = router;
  const domain = useDomain();

  const url = `${domain}${asPath}`;

  if (params) {
    try {
      const { pathname } = new URL(url);
      const queryKeys = Object.keys(query);

      if (params.length && queryKeys.length) {
        let newQuery: Record<string, string | string[] | undefined> = {};

        if (preserveParamOrder) {
          params.forEach((param) => {
            if (query[param] !== undefined) {
              newQuery[param] = query[param];
            }
          });
        } else {
          newQuery = Object.fromEntries(
            Object.entries(query).map(([key, value]) => [key, String(value)]),
          );
          queryKeys.forEach((key: string) => {
            if (!params.includes(key)) {
              delete newQuery[key];
            }
          });
        }

        if (newQuery && "page" in newQuery) {
          const pageNumber = Number(sanitizePage(newQuery.page as string));
          if (pageNumber && pageNumber > 1) {
            newQuery.page = `${pageNumber}`;
          } else {
            delete newQuery["page"];
          }
        }

        const queryString = stringify(newQuery, {
          encode: false,
          indices: false,
        });

        return `${domain}${pathname}${queryString ? `?${queryString}` : ""}`;
      } else {
        return `${domain}${pathname}`;
      }
    } catch {
      return "";
    }
  } else {
    return url;
  }
};
